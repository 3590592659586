import API, { graphqlOperation } from "@aws-amplify/api";
import React, { useContext, useEffect, useState } from "react";
import { LoadMaskContext } from "../../provider/loadmaskprovider";
import { Notification, NotificationType } from "../notification";
import { UserTenantContext } from "../../provider/usertenantprovider";
import * as enrollerStyles from "../enroller.module.css";
import * as buttonStyles from "../button.module.css";
import Select from "react-select";
import { validateNumber } from "../utils";

const listEnrollerConfigurations = /* GraphQL */ `
query listEnrollerConfigurations {
    listEnrollerConfigurations {
        items {
            name
            prettyName
            enrollerGroup
        }
    }
}`

const createOrder = /* GraphQL: */ `
mutation createOrder(
    $tenantId: String!,
    $enrollerConfigurationName: String!,
    $group: String!
    $placedBy: String!
    $itemCode: String!
    $itemDescription: String!
    $quantity: Int!
    $unitOfMeasure: OrderUnitOfMeasure!
    $shipToAccount: String!
    $shipToName: String!
    $shipToAddress: String!
    $shipToState: String!
    $shipToCity: String!
    $shipToZip: String!
    $status: OrderStatus!
) {
    createOrder(input: {
        tenantId: $tenantId,
        enrollerConfigurationName: $enrollerConfigurationName
        group: $group,
        placedBy: $placedBy,
        itemCode: $itemCode,
        itemDescription: $itemDescription,
        quantity: $quantity,
        unitOfMeasure: $unitOfMeasure,
        shipToAccount: $shipToAccount,
        shipToName: $shipToName,
        shipToAddress: $shipToAddress,
        shipToState: $shipToState,
        shipToCity: $shipToCity,
        shipToZip: $shipToZip,
        status: $status,
    }) {
        shipToAccount
    }
}
`

const CreateNewOrder = ({ userContext }) => {
    const [isLoading, setIsLoading] = useContext(LoadMaskContext);
    const [userTenantContext, setUserTenantContext] = useContext(UserTenantContext);
    const [enrollerConfigList, setEnrollerConfigList] = useState();
    const [measurements] = useState([
        { label: "Case", value: "CASE" },
        { label: "Unit", value: "UNIT" },
    ]);
    const [orderItems] = useState([
        { label: "BD VERITOR SARS-COV TEST 30/BX", value: 1384669 },
        { label: "VERITOR PLUS ANALYZER- DS DRPSHIP EA", value: 1265527 }
    ]);
    const [state, setState] = useState({
        tenantId: null,
        enrollerConfigurationName: null,
        group: null,
        placedBy: userContext.username,
        itemCode: null,
        itemDescription: null,
        quantity: "",
        unitOfMeasure: null,
        shipToAccount: null,
        shipToName: "",
        shipToAddress: "",
        shipToState: "",
        shipToCity: "",
        shipToZip: "",
        status: "CREATED",
    });
    const [message, setMessage] = useState({
        show: false,
    });

    const options = userTenantContext.filter(t => t.tenantRole.includes("Admin")).map(tenant => {
        return {
            value: tenant.tenantObj.name,
            label: tenant.tenantObj.prettyName,
        }
    });

    const submitOrder = async () => {
        setMessage({ show: false });
        let validation = {
            quantity: validateNumber(state.quantity),
            shipToZip: validateNumber(state.shipToZip),
        };
        if (validation.quantity == false || validation.shipToZip == false) {
            setMessage({
                show: true,
                msg: "Please make sure Item Quantity or Zip code are numbers.",
                messageType: NotificationType.FAIL,
            });
        } else {
            setIsLoading(true);
            try {
                await API.graphql(
                    graphqlOperation(createOrder, {
                        tenantId: state.tenantId,
                        enrollerConfigurationName: state.enrollerConfigurationName,
                        group: state.group,
                        placedBy: state.placedBy,
                        itemCode: state.itemCode,
                        itemDescription: state.itemDescription,
                        quantity: Number(state.quantity),
                        unitOfMeasure: state.unitOfMeasure,
                        shipToAccount: state.shipToAccount,
                        shipToName: state.shipToName,
                        shipToAddress: state.shipToAddress,
                        shipToState: state.shipToState,
                        shipToCity: state.shipToCity,
                        shipToZip: state.shipToZip,
                        status: state.status,
                    })
                );
                setMessage({
                    show: true,
                    msg: "Successfully created order",
                    messageType: NotificationType.SUCCESS,
                });
            } catch (error) {
                setMessage({
                    show: true,
                    msg: "Failed to create order",
                    messageType: NotificationType.FAIL,
                });
            } finally {
                setState({
                    ...state,
                    quantity: "",
                    shipToName: "",
                    shipToAddress: "",
                    shipToState: "",
                    shipToCity: "",
                    shipToZip: "",
                });
                setIsLoading(false);
            }
        }
    }

    const getEnrollerConfigs = async (tenant) => {
        setIsLoading(true);
        try {
            let results = await API.graphql(graphqlOperation(listEnrollerConfigurations, { tenantId: tenant }));
            setEnrollerConfigList(results.data.listEnrollerConfigurations.items.map(config => {
                return {
                  label: config.prettyName,
                  value: config.name,
                  group: config.enrollerGroup,
                }
            }));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <h1>Create a new order</h1>

            {message.show &&
                <Notification messageType={message.messageType}>
                    <p>{message.msg}</p>
                </Notification>
            }

            <div className={enrollerStyles.form}>
                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label}>
                        Tenant
                    </label>
                    <Select
                        options={options}
                        onChange={e => {
                            setState({ 
                                ...state,
                                tenantId: e.value,
                            });
                            getEnrollerConfigs(e.value)
                        }}
                    />
                </div>

                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label}>
                        Client Site
                    </label>
                    <Select
                        options={enrollerConfigList}
                        isDisabled={!state.tenantId}
                        onChange={e => setState({ 
                            ...state,
                            enrollerConfigurationName: e.value,
                            group: e.group,
                            shipToAccount: e.value
                        })}
                    />
                </div>

                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label}>
                        Order Item
                    </label>
                    <Select
                        options={orderItems}
                        isDisabled={!state.enrollerConfigurationName}
                        onChange={e => setState({
                            ...state,
                            itemCode: e.value,
                            itemDescription: e.label
                        })}
                    />
                </div>


                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label}>
                        Item Quantity
                    </label>
                    <input
                        className={enrollerStyles.input}
                        type="text"
                        value={state.quantity}
                        disabled={!state.enrollerConfigurationName}
                        onChange={e => setState({
                            ...state, quantity: e.currentTarget.value
                        })}
                    />
                </div>

                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label}>
                        Unit of measure
                    </label>
                    <Select
                        options={measurements}
                        isDisabled={!state.enrollerConfigurationName}
                        onChange={e => setState({
                            ...state, unitOfMeasure: e.value
                        })}
                    />
                </div>

                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label}>
                        Name of recipient
                    </label>
                    <input
                        className={enrollerStyles.input}
                        type="text"
                        value={state.shipToName}
                        disabled={!state.enrollerConfigurationName}
                        onChange={e => setState({
                            ...state, shipToName: e.currentTarget.value
                        })}
                    />
                </div>

                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label}>
                        Street address
                    </label>
                    <input
                        className={enrollerStyles.input}
                        type="text"
                        value={state.shipToAddress}
                        disabled={!state.enrollerConfigurationName}
                        onChange={e => setState({
                            ...state, shipToAddress: e.currentTarget.value
                        })}
                    />
                </div>

                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label}>
                        City
                    </label>
                    <input
                        className={enrollerStyles.input}
                        type="text"
                        value={state.shipToCity}
                        disabled={!state.enrollerConfigurationName}
                        onChange={e => setState({
                            ...state, shipToCity: e.currentTarget.value
                        })}
                    />
                </div>

                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label}>
                        State
                    </label>
                    <input
                        className={enrollerStyles.input}
                        type="text"
                        value={state.shipToState}
                        disabled={!state.enrollerConfigurationName}
                        onChange={e => setState({
                            ...state, shipToState: e.currentTarget.value
                        })}
                    />
                </div>

                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label}>
                        Zip code
                    </label>
                    <input
                        className={enrollerStyles.input}
                        type="text"
                        value={state.shipToZip}
                        disabled={!state.enrollerConfigurationName}
                        onChange={e => setState({
                            ...state, shipToZip: e.currentTarget.value
                        })}
                    />
                </div>
            </div>
            <div className={enrollerStyles.inputWrapper}>
                <button
                    type="button"
                    className={buttonStyles.button}
                    disabled={
                        !state.tenantId ||
                        !state.enrollerConfigurationName ||
                        !state.group ||
                        !state.itemCode ||
                        !state.itemDescription ||
                        !state.quantity ||
                        !state.unitOfMeasure ||
                        !state.shipToAccount ||
                        !state.shipToName ||
                        !state.shipToAddress ||
                        !state.shipToState ||
                        !state.shipToCity ||
                        !state.shipToZip
                    }
                    onClick={() => submitOrder()}
                >
                    Submit Order
                    </button>
            </div>
        </>
    );
}

export default CreateNewOrder;