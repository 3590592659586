import React, { useContext } from "react";
import CustomAuth from "../../../components/custom-auth";
import CreateNewOrder from "../../../components/orders/create-new-order";
import SEO from "../../../components/seo";
import { UserContext } from "../../../provider/usercontextprovider";
import { UserTenantProvider  } from "../../../provider/usertenantprovider";

const ProtectedPage = () => {
    const [userContext] = useContext(UserContext);
    const isLogistician =
        userContext.username &&
        userContext.signInUserSession.accessToken.payload["cognito:groups"].includes("Logistician");

    return (
        <CustomAuth>
            <SEO title="Create New Order" />
            <UserTenantProvider>
                {isLogistician ? <CreateNewOrder userContext={userContext} /> : <p>User does not have correct permissions to access this page</p>}
            </UserTenantProvider>
        </CustomAuth>
    );
}

export default ProtectedPage;